import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantService } from '../tenant.service';
import { LocalStoreManager } from '../local-store/local-store-manager.service';
import { LocalStoreKeys } from '../local-store/local-store-keys.service';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {



  constructor(private localStore: LocalStoreManager) { }


  private addTenantToHeader(request: HttpRequest<any>, tenantName: string | null) {
    if (tenantName && !request.headers.has('Selected-Tenant')) {
      request = request.clone({
        setHeaders: {
          "Selected-Tenant": tenantName.toString(),
        }
      });
    }
    return request;
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addTenantToHeader(request, this.localStore.getDataObject<string>(LocalStoreKeys.SELECTED_TENANT)));
  }
}