import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalStoreKeys } from './local-store/local-store-keys.service';
import { LocalStoreManager } from './local-store/local-store-manager.service';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';

export enum Tenants {
  DEV = "development",
  NOVOSTORM = "novostorm"
}

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  private _selectedTenant: Tenants | null = null;

  public currentTenant = new BehaviorSubject<Tenants | null>(null);

  constructor(
    private localStore: LocalStoreManager,
    private authService: AuthService,
    private router: Router,
  ) {
    this.loadPersistedTenant();
    this.setDefaultTenant();

    this.authService.loggedOut.subscribe(() => this.clearSelectedTenant());
    this.authService.userUpdated.subscribe(() => {
      if (this._selectedTenant && !this.authService.hasTenant(this._selectedTenant)) {
        this.changeSelectedTenant(this._selectedTenant)
      }
    });
  }

  setDefaultTenant() {
    if (!this._selectedTenant) {

      if (Array.isArray(this.authService.currentUser.tenant)) {
        this.changeSelectedTenant(this.authService.currentUser.tenant[0]);
      } else {
        this.changeSelectedTenant(this.authService.currentUser.tenant);
      }

    }
  }

  public get selectedTenant() {
    return this._selectedTenant;
  }

  public changeSelectedTenant(tenantName: Tenants) {
    console.log('tenantname', tenantName)
    if (!this.authService.hasTenant(tenantName)) {
      this.authService.refreshAccessToken().subscribe(r => {
        if (this.authService.hasTenant(tenantName)) {
          this._selectedTenant = tenantName;
          this.persistSelectedTenant();
          this.reloadCurrentRoute();
          this.currentTenant.next(tenantName);
        }
      });
    } else {
      this._selectedTenant = tenantName;
      this.persistSelectedTenant();
      this.reloadCurrentRoute();
      this.currentTenant.next(tenantName);
    }
  }

  private clearSelectedTenant() {
    this._selectedTenant = null;
    this.currentTenant.next(null)
  }

  private reloadCurrentRoute() {
    const uri = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri], { skipLocationChange: true }));
  }

  private persistSelectedTenant() {

    if (this.authService.rememberSession)
      this.localStore.savePermanentData(this._selectedTenant, LocalStoreKeys.SELECTED_TENANT);
    else
      this.localStore.saveSyncedSessionData(this._selectedTenant, LocalStoreKeys.SELECTED_TENANT);
  }

  private loadPersistedTenant() {
    if (this.localStore.exists(LocalStoreKeys.SELECTED_TENANT)) {
      const loadedTenant = this.localStore.getDataObject<Tenants>(LocalStoreKeys.SELECTED_TENANT);
      if (this.authService.hasTenant(loadedTenant)) {
        console.log('persisted tenant loaded')
        this._selectedTenant = loadedTenant;
        this.currentTenant.next(loadedTenant)
      }
    }
  }

  // public getTenants(): Observable<Tenants[]> {
  //   let controller = 'account/tenants'

  //   return this.http.get<Tenants[]>(environment.apiEndpoint + controller, { headers: { "Content-Type": "application/json" } });
  // }
}
