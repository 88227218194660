import { LocalStoreKeys } from '../local-store/local-store-keys.service';
import { LocalStoreManager } from '../local-store/local-store-manager.service';
import { LoginResponse as AuthTokens  } from 'src/app/models/login-response.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpHandler } from '@angular/common/http';
import { Router, NavigationExtras } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map, flatMap, catchError } from 'rxjs/operators';
import { Observable, of, Subject, throwError } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { Tenants } from '../tenant.service';
import { AccesLogService } from '../api/acces-log.service';
import { Console } from '@angular/compiler/src/util';
export enum UserRoles {
  ADMIN = "admin",
  ASSESSOR = "assessor",
  USER = "user",
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public static readonly apiVersion: string = "1";
  private clientId = "angular_client";
  public loginUrl = "/admin/login/";
  public loginIframeUrl = "/login-iframe";
  private tokenEndpoint = "connect/token";
  private userInfoEndpoint = "connect/userinfo";
  private revocationEndpoint = "connect/revocation";
  private user: any;
  private extern: boolean | undefined;
  public tokens: any;
  loginRedirectUrl: string | undefined;
  private remember: boolean | undefined;
  private tokenExpirationDate: Date | undefined;
  public loggedOut = new Subject<void>();
  public userUpdated = new Subject();
  isIn: boolean | undefined;

  refreshTokenInProgress = false;
  refreshToken: Observable<string> | undefined;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  private http: HttpClient;

  constructor(
    private handler: HttpHandler,
    private router: Router,
    private localStore: LocalStoreManager,
    private accesLogService:AccesLogService,
  ) {
    this.http = new HttpClient(this.handler);
    this.loadSavedConfig();
  }


  public get rememberSession(): boolean | undefined {
    return this.remember;
  }


  public get isAdmin(): boolean {
    console.log("role:", this.currentUser.role);
    if (Array.isArray(this.currentUser.role))
      return this.currentUser.role.includes(UserRoles.ADMIN);
    else
      return this.currentUser.role === UserRoles.ADMIN;
  }

  // public get isAdminGroup(): boolean {

  //     if (Array.isArray(this.currentUser.role))
  //         return this.currentUser.role.includes(UserRoles.ADMINGRUP);
  //     else
  //         return this.currentUser.role === UserRoles.ADMINGRUP;
  // }

  // isGranted(permission: PermissionType): boolean {

  //   const roles: PermissionBase[] = this.permissionsManager.getInstance();

  //   for (const role of roles) {

  //     for (const permissionBase of roles) {

  //       for (let perm of permissionBase.permissions) {

  //         if (perm === permission) {
  //           return true;
  //         }
  //       }
  //     }

  //   }

  //   return false;
  // }

  // isGrantedStringFormat(permissionString: 'READ' | 'CREATE' | 'DELETE' | 'UPDATE'): boolean {

  //     const permission = PermissionType[permissionString]
  //     return this.isGranted(permission);

  // }

  public hasRole(role: UserRoles): boolean {
    if (Array.isArray(this.currentUser.role))
      return this.currentUser.role.includes(role);
    else
      return this.currentUser.role === role;
  }

  //[loginResults.refresh_token, LocalStoreKeys.REFRESH_TOKEN],
  // [loginResults.access_token, LocalStoreKeys.ACCESS_TOKEN],
  // [loginResults, LocalStoreKeys.TOKENS],
  // [this.tokenExpirationDate, LocalStoreKeys.TOKEN_EXPIRATION_DATE],
  // [user, LocalStoreKeys.CURRENT_USER],
  // [permanent, LocalStoreKeys.REMEMBER],
  loadSavedConfig() {
    this.user = this.localStore.getDataObject<User>(LocalStoreKeys.CURRENT_USER);
    this.tokens = this.localStore.getDataObject<AuthTokens>(LocalStoreKeys.TOKENS);
    this.remember = this.localStore.getData(LocalStoreKeys.REMEMBER);
    const expiration = this.localStore.getData(LocalStoreKeys.TOKEN_EXPIRATION_DATE);
    if (expiration) {
      this.tokenExpirationDate = new Date(expiration);
    }

  }

  get currentUser(): User {
    return this.localStore.getDataObject<User>(LocalStoreKeys.CURRENT_USER);
  }


  get isTokenExpired(): boolean {
    const dt = new Date();
    const expired = !this.tokenExpirationDate || this.tokenExpirationDate <= dt;
    return expired;
  }


  get isLoggedIn(): boolean {
    return this.tokens != null && this.tokens.refresh_token != null;
  }


  getAccessToken(): Observable<string> {
    if (!this.isLoggedIn)
      return of('null');

    // if(this.isTokenExpired)
    //   return this.refreshAccessToken();
    this.tokens = this.localStore.getDataObject<AuthTokens>(LocalStoreKeys.TOKENS);
    return of(this.tokens.access_token);
  }


  // Actualitza l'access token fent servir un refresh token, si la operació ja està
  // en procés, s'espera a que acabi, executant-se només un cop.
  refreshAccessToken(): Observable<string> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        // Espera a que s'actualitzi el token
        this.tokenRefreshed$.subscribe(continueOp => {
          if (!continueOp)
            return observer.error("request cancelled");

          observer.next(this.tokens.access_token);
          return observer.complete();
        });
      });
    }
    else {
      this.refreshTokenInProgress = true;
      return this.refreshTokens().pipe(map(() => {
        this.refreshTokenInProgress = false;
        this.tokenRefreshedSource.next(true);
        return this.tokens.access_token;
      }), catchError((err) => {
        this.refreshTokenInProgress = false;
        this.tokenRefreshedSource.next(false);
        return throwError(err);
      }));
    }
  }


  redirectToLogin() {
    this.router.navigate([this.loginUrl]);
  }


  redirectLoginUser() {
    this.loginRedirectUrl = '/admin';
    const redirect = this.loginRedirectUrl && this.loginRedirectUrl !== '/' ? this.loginRedirectUrl : "/";

    const urlParamsAndFragment = this.splitInTwo(redirect, '#');
    const urlAndParams = this.splitInTwo(urlParamsAndFragment.firstPart, '?');

    const navigationExtras: NavigationExtras = {
      fragment: urlParamsAndFragment.secondPart,
      queryParams: this.getQueryParamsFromString(urlAndParams.secondPart),
      queryParamsHandling: "merge",
      skipLocationChange: true
    };
   
    this.accesLogService.enterLog().subscribe(()=>{
      
    },error=>{},()=>{})
    this.router.navigate([this.loginRedirectUrl]);//, navigationExtras).then(() => history.replaceState({}, null, urlAndParams.firstPart));
  }

  redirectPassChange() {
    this.router.navigate(['account/groups/actualitzar-contrasenya']);
  }


  login(userName: string, password: string, rememberMe: boolean = false): Observable<void> {

    if (this.isLoggedIn)
      this.logout(!'redirect');

    this.remember = rememberMe;

    const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const params = new HttpParams()
      .append('username', userName)
      .append('password', password)
      .append('client_id', this.clientId)
      .append('grant_type', 'password');
    // .append('scope', 'openid email profile offline_access roles gestio_projectes');
    return this.http.post<AuthTokens>(environment.loginServer + this.tokenEndpoint, params, { headers: header }).pipe(
      flatMap(response => {
        this.isIn = true;
        return this.processLoginResponse(response);
      }
      )
    );
  }


  // Elimina la informació local de l'usuari (access tokens, refresh tokens... etc)
  logout(redirect: boolean = true, redirectUrl: string = '/admin/login') {
    this.accesLogService.exitLog().subscribe(()=>{},error=>{
      
    })
    this.isIn = false;
    this.revokeToken();
    this.deleteSavedResults();
    this.tokens = null;
    this.user = null;
    
    if (redirect) {
      this.router.navigate([redirectUrl]);
    }
  }

  private revokeToken() {
    if (!this.tokens) {
      return;
    }
    const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const params = new HttpParams()
      .append('client_id', this.clientId)
      .append('token', this.tokens.refresh_token)
      .append('token_type_hint', 'refresh_token');
    return this.http.post(environment.loginServer + this.revocationEndpoint, params, { headers: header }).subscribe(response => {
      this.tokens = null;
      this.user = null;
      this.deleteSavedResults();
      this.loggedOut.next();

      // if (!noRedirect) {
      //   this.router.navigate([this.loginUrl]);
      // }
    }, err => {
      console.log("LogOut err");
    });
  }

  private getUserInfo(accessToken: string): Observable<User> {
    const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const params = new HttpParams().append('access_token', accessToken);
    return this.http.post<User>(environment.loginServer + this.userInfoEndpoint, params, { headers: header });
  }


  private refreshTokens(): Observable<void> {
    const header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams()
      .append('client_id', this.clientId)
      .append('refresh_token', this.tokens.refresh_token)
      .append('grant_type', 'refresh_token');
    // .append('scope', 'openid email profile offline_access roles gestio_projectes');

    return this.http.post<AuthTokens>(environment.loginServer + this.tokenEndpoint, params, { headers: header }).pipe(
      flatMap(response => {
        return this.processLoginResponse(response);
      })
    );
  }


  private processLoginResponse(response: AuthTokens): Observable<void> {
    this.tokens = response;
    return this.getUserInfo(response.access_token).pipe(
      map(result => {
        this.tokens = response;
        this.user = result;
        const date = new Date();
        date.setTime(date.getTime() + (response.expires_in * 1000) - (180 * 1000));
        this.tokenExpirationDate = date;
        this.saveLoginResults(response, result, this.remember);
        this.userUpdated.next({});
      })
    );
  }

  private saveLoginResults(loginResults: AuthTokens, user: User, permanent: boolean = false) {
    // this.tokens = loginResults;
    const data: [any, string][] = [
      [loginResults.refresh_token, LocalStoreKeys.REFRESH_TOKEN],
      [loginResults.access_token, LocalStoreKeys.ACCESS_TOKEN],
      [loginResults, LocalStoreKeys.TOKENS],
      [this.tokenExpirationDate, LocalStoreKeys.TOKEN_EXPIRATION_DATE],
      [user, LocalStoreKeys.CURRENT_USER],
      [permanent, LocalStoreKeys.REMEMBER],
    ];
    //   if(permanent)
    //   {
    data.forEach(([item, key]) => {
      this.localStore.savePermanentData(item, key);
    });

    //this.tokens = this.localStore.getDataObject<AuthTokens>(LocalStoreKeys.TOKENS);

    //  }
    // else
    //  {
    //  data.forEach(([item, key]) => {
    //      this.localStore.saveSyncedSessionData(item, key);
    //  });
    //  }
  }


  private deleteSavedResults() {
    this.localStore.clearAllStorage();
  }


  private splitInTwo(text: string, separator: string): { firstPart: string, secondPart: any } {
    let separatorIndex = text.indexOf(separator);

    if (separatorIndex == -1)
      return { firstPart: text, secondPart: null };

    const part1 = text.substr(0, separatorIndex).trim();
    const part2 = text.substr(separatorIndex + 1).trim();

    return { firstPart: part1, secondPart: part2 };
  }


  private getQueryParamsFromString(paramString: string) {
    if (!paramString)
      return null;

    let params: { [key: string]: string } = {};
    for (let param of paramString.split("&")) {
      let keyValue = this.splitInTwo(param, "=");
      params[keyValue.firstPart] = keyValue.secondPart;
    }

    return params;
  }

  public hasTenant(tenant: Tenants): boolean {
    if (Array.isArray(this.currentUser.tenant))
      return this.currentUser.tenant.includes(tenant);
    else
      return this.currentUser.tenant === tenant;
  }
}


