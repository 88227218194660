import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/public/home/home.module').then(m => m.HomeModule) },
  { path: 'login', loadChildren: () => import('./modules/admin/login/login.module').then(m => m.LoginModule) },
  { path: 'admin', loadChildren: () => import('./modules/admin/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
