import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.checkLogin(url, '/login');

  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    let url = `/${route.path}`;
    return this.checkLogin(url, '/login');
  }

  checkLogin(url: string, redirectRoute: string): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }

    // Guardem la url per redireccionar a l'usuari despres d'identificarse
    this.authService.loginRedirectUrl = url;
    this.router.navigate([redirectRoute], { skipLocationChange: true }).then(() => history.replaceState({}, '', redirectRoute));
    return false;
  }
}
