import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID,NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './services/auth/auth.service';
import { LocalStoreManager } from './services/local-store/local-store-manager.service';
import { AuthGuard } from './guards/auth.guard';
import { PermissionGuard } from './guards/permission.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoaderInterceptor } from './services/loader/loader.interceptor';
import { registerLocaleData } from '@angular/common';
import localeCA from '@angular/common/locales/ca';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './services/interceptors/token.interceptor';
import { TenantInterceptor } from './services/interceptors/tenant.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
registerLocaleData(localeCA);
export const interceptorProviders =
  [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ];

@NgModule({
  declarations: [
    AppComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [ LocalStoreManager,
    AuthService,
    AuthGuard,
    PermissionGuard,
    MatSnackBarModule,
    { provide: LOCALE_ID, useValue: 'ca' },
    ...interceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
