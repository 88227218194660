import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, UserRoles } from '../services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { snackBarConfigError } from '../modules/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const roles: UserRoles[] = route.data['roles'];
    const superuser: boolean = !!route.data['superuser'];

    if (superuser) {
      const userEmail: string = this.authService.currentUser.email;
      return this.hasRoles(roles) && userEmail.includes('novostorm');
    } else {
      return this.hasRoles(roles);
    }
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {

    if (route.data && route.data['roles']) {
      const roles: UserRoles[] = route.data['roles'];
      const superuser: boolean = !!route.data['superuser'];

      if (superuser) {
        const userEmail: string = this.authService.currentUser.email;
        return this.hasRoles(roles) && userEmail.includes('novostorm');
      } else {
        return this.hasRoles(roles);
      }

    } else {
      this.router.navigate(['/admin/login'], { skipLocationChange: true }).then(() => history.replaceState({}, '', '/admin/login'));
      return false;
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  hasRoles(roles: UserRoles[]): boolean {

    let hasRole: boolean = false;

    roles.forEach(role => {
      if (this.authService.hasRole(role)) {
        hasRole = true;
      }
    });

    if (hasRole) {
      return true;
    } else {

      if (this.authService.isLoggedIn) {
        this.snackBar.open( `No té els permissos d'accés necessaris`, '', snackBarConfigError);
      }

      this.authService.logout(!'redirect');
      this.router.navigate(['/admin/login'], { skipLocationChange: true }).then(() => history.replaceState({}, '', '/admin/login'));
      return false;
    }
  }
}
