import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';
//import { BYPASS_INTERCEPTOR } from 'src/app/modules/shared/constants';
import { Router } from '@angular/router';
import { TenantService } from '../tenant.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private router: Router,
        private tenantService: TenantService
    ) { }


    private addTokenToHeader(request: HttpRequest<any>, accessToken: string) {
        if (accessToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: `application/vnd.iman.v${AuthService.apiVersion}+json, application/json, text/plain, */*`,
                }
            });
        }
        return request;
    }


    logout() {
        this.authService.logout();
        // this.router.navigate(['/user/' + this.tenantService.selectedTenant])
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        /*if (request.context.get(BYPASS_INTERCEPTOR) === true) {
            return next.handle(request);
        }*/

        return this.authService.getAccessToken().pipe(
            catchError((err) => {
                console.error("No s'ha pogut obtenir un token: " + err);
                if (err.status && (err.status >= 400 && err.status < 500)) {
                    this.logout();
                    // this.snackBar.open($localize "Ha expirat la sessió.", null, snackBarConfigError);
                }
                return throwError(err);
            }),
            flatMap(token => {
                return next.handle(this.addTokenToHeader(request, token)).pipe(
                    catchError((error => {
                        if (error.status === 401) // Unauthorized
                        {
                            // Es possible que s'hagi tancat la sessió
                            // Provem de refrescar el token
                            return this.authService.refreshAccessToken().pipe(
                                catchError(err => {
                                    console.error("No s'ha pogut refrescar el token: " + err);
                                    if (err.status && (err.status >= 400 && err.status < 500)) {
                                        // Tanquem la sessió perque no s'ha pogut obtenir un nou token
                                        this.logout();
                                        // this.snackBar.open($localize "Ha expirat la sessió.", null, snackBarConfigError);
                                    }
                                    return throwError(err);
                                }),
                                flatMap((token2) => {
                                    return next.handle(this.addTokenToHeader(request, token2));
                                }),
                            );
                        }
                        else if (error.status === 0 || error.status === 504) {
                            //TODO Error de connexió?
                        }
                        return throwError(error);
                    }))
                );
            })
        );
    }
}
