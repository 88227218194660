//import { HttpContextToken } from '@angular/common/http';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

export const snackBarConfigError: MatSnackBarConfig = {
    horizontalPosition: "center",
    verticalPosition: "top",
    duration: 5000,
    panelClass: ['snackbar-error']
};

export const snackBarConfigOk: MatSnackBarConfig = {
    horizontalPosition: "center",
    verticalPosition: "top",
    duration: 5000,
    panelClass: ['snackbar-ok']
};

//export const BYPASS_INTERCEPTOR = new HttpContextToken(() => false);