import { Injectable } from '@angular/core';

@Injectable()
export class LocalStoreKeys
{
    public static readonly CURRENT_USER = "current_user";

    // Auth
    public static readonly TOKEN_EXPIRATION_DATE = "expiration_date";
    public static readonly REMEMBER = "remember";
    public static readonly USER_CONFIG = "user_config";
    public static readonly TOKENS = "tokens";
    public static readonly REFRESH_TOKEN:any;
    public static readonly ACCESS_TOKEN:any;
    // Tenants
    public static readonly SELECTED_TENANT = "selected_tenant";
}