import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PwaInstallationService {
  deferredPrompt: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() { }

  showPrompt() {
    this.deferredPrompt.getValue().prompt();
  }
}
