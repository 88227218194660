import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccesLogService {
  private controllerUrl = environment.apiEndpoint + 'account/log';
  constructor(protected http: HttpClient) { }

  enterLog(){
    return this.http.post(this.controllerUrl,'true', { headers: { "Content-Type": "application/json" } });
  }

  exitLog(){
    return this.http.post(this.controllerUrl,'false', { headers: { "Content-Type": "application/json" } });
  }


}
