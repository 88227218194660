import { Component } from '@angular/core';
import { PwaInstallationService } from './services/pwa-installation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ApsaAngular';

  constructor(private pwaInstallationService: PwaInstallationService) { }

  ngOnInit() {
    console.log('init')
    // Add the event listener to 'beforeinstallprompt'
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.pwaInstallationService.deferredPrompt.next(e);
    });
  }
}
